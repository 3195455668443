import s from "../RequestForm/RequestForm.module.scss";
import ModalIcon from "../../images/modalIcon.svg";
import { useEffect, useState } from "react";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { deleteAccount, login } from "../utils/fetch.js";
import Loader from "../utils/Loader.jsx";

const initialForm = {
  login: "",
  password: "",
};

const initialFormErrors = {
  login: false,
  password: false,
};

const loaderWrapStyle = {
  position: "absolute",
  bottom: 0,
  left: "50%",
  transform: "translate(-50%)",
};

const DeleteUserDataForm = ({ closeModal, track }) => {
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState(initialFormErrors);
  const [loading, setLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [agreeWithDelete, setAgreeWithDelete] =
    useState(false);
  const [agreeWithDeleteError, setAgreeWithDeleteError] =
    useState(false);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value.trimStart(),
    });
  };

  const onRequestFormSubmit = async (e) => {
    e.preventDefault();
    const isFormValid = onRequestFormValidation();

    if (isFormValid) {
      setLoading(true);
      setBtnDisable(true);
      const loginRes = await login({
        email: form.login,
        password: form.password,
      });
      window.dataLayer.push({
        event: "onRequest_form_submit",
      });

      if (!!loginRes) {
        const delRes = await deleteAccount(loginRes);

        if (delRes) {
          setLoading(false);
          setForm(initialForm);
          setErrors(initialFormErrors);
          setBtnDisable(false);
          setAgreeWithDeleteError(false);

          Notify.success(
            "All your data has been successfully deleted."
          );
          closeModal();
        } else {
          setLoading(false);
          setBtnDisable(false);
          setForm(initialForm);
          setErrors(initialFormErrors);
          setAgreeWithDeleteError(false);
          Notify.failure(
            "Something goes wrong.Please try again."
          );
          closeModal();
        }
      } else {
        setLoading(false);
        setBtnDisable(false);
        setForm(initialForm);
        setErrors(initialFormErrors);
        Notify.failure(
          "Something goes wrong.Please try again."
        );
      }
    }
  };

  const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

  const onRequestFormValidation = () => {
    let newErrors = {};
    let isValid = true;

    if (form.login.trimStart() === "") {
      newErrors.login = true;
      isValid = false;
    }
    if (form.password.trimStart() === "") {
      newErrors.password = true;
      isValid = false;
    }

    if (!agreeWithDelete) {
      setAgreeWithDeleteError(true);
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const onInputErrorsSet = (e) => {
    const inputName = e.target.name;
    setErrors({
      ...errors,
      [inputName]: false,
    });
  };

  // useEffect(() => {
  //   addTrackToForm();
  // }, [track]);

  return (
    <div>
      <div className={s.modal__wrap}>
        <div className={s.modal__headerWrap}>
          <h2 className={s.modal__header}>
            Delete all my data
          </h2>

          <img
            className={s.modal__headerClose_icon}
            src={ModalIcon}
            alt="remove icon"
            onClick={closeModal}
          />
        </div>
        <p className={s.modal__text}>
          By sending this request all your personal data
          will be removed from our system.
        </p>
        <p className={s.modal__text}>
          Please enter your login and password for the
          Choizy app.
        </p>
        <form
          className={s.modal__form}
          onSubmit={onRequestFormSubmit}
        >
          <label className={s.modal__form_label}>
            Login
            <input
              onChange={onInputChange}
              className={[
                !errors.login
                  ? s.modal__form_input +
                    " " +
                    "inputNormal"
                  : s.modal__form_input +
                    " " +
                    "inputError",
              ]}
              onFocus={(e) => {
                onInputErrorsSet(e);
              }}
              type="text"
              placeholder={
                errors.name ? "Enter login" : "Your name"
              }
              name="login"
              value={form.login}
            />
          </label>
          <label className={s.modal__form_label}>
            Password
            <input
              onChange={onInputChange}
              className={[
                !errors.password
                  ? s.modal__form_input +
                    " " +
                    "inputNormal"
                  : s.modal__form_input +
                    " " +
                    "inputError",
              ]}
              onFocus={(e) => {
                onInputErrorsSet(e);
              }}
              type="password"
              placeholder="Your password"
              name="password"
              value={form.password}
            />
          </label>
          <label
            className={[
              !agreeWithDeleteError
                ? s.footer__form_item +
                  " " +
                  s.modal__checkBoxWrap +
                  " " +
                  "footerCheckboxNorm"
                : s.footer__form_item +
                  " " +
                  s.modal__checkBoxErrWrap +
                  " " +
                  "footerCheckboxErr",
            ]}
          >
            I understand that by submitting this request,
            all my data will be permanently removed with no
            possibility of restoration.
            <input
              className={s.footer__form_checkboxInput}
              onClick={(e) => {
                setAgreeWithDeleteError(!e.target.value);
              }}
              onChange={() =>
                setAgreeWithDelete(!agreeWithDelete)
              }
              type="checkbox"
              name="agreeWithDelete"
              value={agreeWithDelete}
            />
            <span
              className={[
                !errors.track
                  ? s.geekmark + " " + "geekmarkNorm"
                  : s.geekmark + " " + "geekmarkErr",
              ]}
            ></span>
          </label>

          <button
            disabled={btnDisable}
            className={s.modal__form_btn}
          >
            Send
          </button>
          {loading && (
            <Loader loaderWrapStyle={loaderWrapStyle} />
          )}
        </form>
      </div>
    </div>
  );
};

export default DeleteUserDataForm;
