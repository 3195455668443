import s from "./Google.module.scss";
import google from "../../images/google.svg";

const Google = () => {
  return (
    <section className={s.google}>
      <div className="container">
        <div
          className={s.google__wrapper}
        >
          <div
            className={
              s.google__secondary_wrap
            }
          >
            <div
              className={s.google__text}
            >
              <p>
                We’re part of the
                <span
                  className={
                    s.google__text_bold
                  }
                >
                  Google for Startups
                  2023
                </span>
                alumni
              </p>
              <p>
                <span
                  className={
                    s.google__text_blue
                  }
                >
                  @GoogleStartups
                </span>
                ,connecting startups
                with the right
              </p>
              <p>
                people, products and
                best practices to help
                them grow.
              </p>
            </div>
          </div>
          <span
            className={s.google__img}
            style={{
              backgroundImage: `url(${google})`,
            }}
          ></span>
        </div>
      </div>
    </section>
  );
};

export default Google;
