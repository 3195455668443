import {
  useEffect,
  useState,
} from "react";
import "./App.scss";
import AboutUs from "./components/AboutUs/AboutUs";
import FAQ from "./components/FAQ/FAQ";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import HowItWorks from "./components/HowItWorks/HowItWorks";
import Intro from "./components/Intro/Intro";
import OurProducts from "./components/OurProducts/OurProducts";
import Reviews from "./components/Reviews/Reviews";
import Google from "./components/Google/Google";

import { useLocation } from "react-router-dom";

function App() {
  const { hash } = useLocation();

  const [modal, setModal] = useState({
    open: false,
    content: null,
  });

  const closeModal = () => {
    document.body.style.overflow =
      "scroll";
    setModal({ open: false });
    window.dataLayer.push({
      event: "onHeaderModal_Btn_Close",
    });
  };

  const onRequestBtnClick = () => {
    window.dataLayer.push({
      event:
        "onRequest_headerBtn_Click",
    });
    document.body.style.overflow =
      "hidden";
    setModal({ open: true });
  };

  const onScroll = () => {
    const elementIdToFind =
      hash.substring(1);
    const element =
      document.getElementById(
        elementIdToFind
      );

    if (element) {
      const scrollTo =
        element.offsetTop - 85;
      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    onScroll();
  }, [hash]);

  return (
    <div className="App">
      <Header
        modal={modal}
        onRequestBtnClick={
          onRequestBtnClick
        }
        closeModal={closeModal}
      />
      <Intro
        onRequestBtnClick={
          onRequestBtnClick
        }
      />
      <AboutUs />
      <HowItWorks
        onRequestBtnClick={
          onRequestBtnClick
        }
      />
      <Reviews />
      <OurProducts />
      <FAQ />
      <Google />
      <Footer />
    </div>
  );
}

export default App;
