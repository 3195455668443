import { Oval } from "react-loader-spinner";

const Loader = ({ loaderWrapStyle }) => {
  return (
    <Oval
      height={50}
      width={50}
      color="#00dc97"
      wrapperStyle={loaderWrapStyle}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#00dc97"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
};

export default Loader;
