import { useState } from "react";
import s from "./HideText.module.scss";
import arrow from "../../../images/coolicon.svg";
import decorLine from "../../../images/decor_line_long.png";

const HideText = ({ setShowProgressBar, showProgressBar, text, children }) => {
  const [isHidden, setIsHidden] = useState(false);

  const handleArrowClick = () => {
    setShowProgressBar && setShowProgressBar(!showProgressBar);
    setIsHidden(!isHidden);
    window.dataLayer.push({ event: "onSection_arrow_Click" });
  };

  return (
    <div className={s.main__wrapper}>
      <div className={s.wrapper}>
        <p className={s.text}>{text}</p>
        <span
          className={s.line}
          style={{
            backgroundImage: `url(${decorLine})`,
          }}
        ></span>
        <div className={s.toggleArrow__wrap} onClick={handleArrowClick}>
          <img
            className={isHidden ? "arrow_up" : "arrow_down"}
            src={arrow}
            alt="arrow"
          />
        </div>
      </div>
      <p className={s.text__mobile}>{text}</p>
      <div className={isHidden ? "hide-text" : "show-text"}>{children}</div>
    </div>
  );
};

export default HideText;
