import s from "./FAQ.module.scss";
import { FAQ_Data } from "../utils/data";
import HideText from "../utils/HideText/HideText";
import { useState } from "react";
import Modal from "../utils/Modal/Modal";
import { Link } from "react-router-dom";
import DeleteUserDataForm from "../DeleteUserDataForm/DeleteUserDataForm";

const FAQ = () => {
  const [openDelDataModal, setOpenDelDataModal] =
    useState(false);

  const onPlusIconClick = (e, id) => {
    const iconWrap = e.currentTarget;
    iconWrap.classList.toggle("faq__iconWrap_click");

    const currentBtnWrap = document.getElementById(id);
    currentBtnWrap.classList.toggle("hide-list");
    window.dataLayer.push({
      event: "onFQA_plusIcon_click",
    });
  };

  const onRenderText = (text, id) => {
    if (id === 10) {
      const parts = text.split("link");

      return (
        <p className={s.answer}>
          {parts[0]}
          <Link onClick={() => setOpenDelDataModal(true)}>
            link.
          </Link>
        </p>
      );
    } else {
      return <p className={s.answer}>{text}</p>;
    }
  };

  return (
    <section className={s.questions__section} id="FAQ">
      <div className="container">
        <HideText text={"FAQs"}>
          <ol className={s.questions__list}>
            {FAQ_Data.map((el) => (
              <li className={s.questions__item} key={el.id}>
                <div className={s.questions__item_mainWrap}>
                  <p className={s.questions}>
                    {el.question}
                  </p>

                  <div
                    className={s.questions__svgWrap}
                    onClick={(e) =>
                      onPlusIconClick(e, el.id)
                    }
                  >
                    <svg
                      className={s.questions__svg}
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.5 0.428467H6.5V6.42847H0.5V8.42847H6.5V14.4285H8.5V8.42847H14.5V6.42847H8.5V0.428467Z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="hide-list" id={el.id}>
                  <ol className={s.answer__list}>
                    {el.answers.map((answer, subIndex) => (
                      <li
                        className={s.answer__item}
                        key={subIndex}
                      >
                        {onRenderText(answer, el.id)}
                      </li>
                    ))}
                  </ol>
                </div>
              </li>
            ))}
          </ol>
        </HideText>
      </div>
      {openDelDataModal && (
        <Modal
          handleClose={() => setOpenDelDataModal(false)}
        >
          <DeleteUserDataForm
            closeModal={() => setOpenDelDataModal(false)}
          />
        </Modal>
      )}
    </section>
  );
};

export default FAQ;
