import s from "./RequestSuccessful.module.scss";
import Img from "../../images/rafiki.png";

const RequestSuccessful = ({
  closeModal,
}) => {
  return (
    <div className={s.wrap}>
      <p className={s.heading}>
        Thank you for your interest!
      </p>
      <img
        className={s.img}
        src={Img}
        alt="email box"
      />
      <p className={s.desc}>
        We will contact your shortly
      </p>
      <button
        className={s.btn}
        onClick={closeModal}
      >
        Close
      </button>
    </div>
  );
};

export default RequestSuccessful;
