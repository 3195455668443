import s from "./Intro.module.scss";
import decorLine from "../../images/decor_line.png";

import Facebook from "../../images/facebook.svg";
import LinkedIn from "../../images/linkedIn.svg";
import Instagram from "../../images/instagram.svg";
import Student_1 from "../../images/student1.jpg";
import Student_2 from "../../images/student2.jpg";
import Student_3 from "../../images/student3.jpg";

const Intro = ({ onRequestBtnClick }) => {
  const onSocialIconClick = () => {
    window.dataLayer.push({ event: "onHeaderSocial_Icon_click" });
  };
  return (
    <div className={s.wrap}>
      <div className="container">
        <div className={s.introImg__wrap}>
          <div className={s.heading__wrap}>
            <div className={s.heading__secondary_wrap}>
              <p className={s.heading}>Helping students</p>
              <div className={s.heading__flex_baseline}>
                <img
                  className={s.heading__first_img}
                  src={Student_1}
                  alt="student1"
                />
                <span
                  className={s.heading__decor_firstLine}
                  style={{
                    backgroundImage: `url(${decorLine})`,
                  }}
                ></span>
              </div>
            </div>
            <div className={s.heading__secondary_wrap}>
              <img
                className={s.heading__second_img}
                src={Student_2}
                alt="student2"
              />
              <p className={s.heading}>to make the best </p>
            </div>
            <div className={s.heading__secondary_wrap}>
              <div className={s.heading__flex_baseline}>
                <span
                  className={s.heading__decor_thirdLine}
                  style={{
                    backgroundImage: `url(${decorLine})`,
                  }}
                ></span>
                <p className={s.heading}>career choice</p>
              </div>
              <img
                className={s.heading__third_img}
                src={Student_3}
                alt="student3"
              />
            </div>
            <span
              className={s.heading__decor_line}
              style={{
                backgroundImage: `url(${decorLine})`,
              }}
            ></span>
          </div>
          <button className={s.heading__btn} onClick={onRequestBtnClick}>
            Request a demo
          </button>
          <div className={s.arrow__down}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul className={s.socialIcon__list}>
            <li className={s.socialIcon__item} onClick={onSocialIconClick}>
              <a href="https://www.instagram.com/choi_zy/" target="_blank">
                <img src={Instagram} alt="Instagram icon" />
              </a>
            </li>
            <li className={s.socialIcon__item} onClick={onSocialIconClick}>
              <a href="https://www.facebook.com/choizy.ukr" target="_blank">
                <img src={Facebook} alt="Facebook icon" />
              </a>
            </li>
            <li className={s.socialIcon__item} onClick={onSocialIconClick}>
              <a
                href="https://www.linkedin.com/company/choizy/mycompany/"
                target="_blank"
              >
                <img src={LinkedIn} alt="LinkedIn icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Intro;
