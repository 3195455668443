import { useState } from "react";
import s from "./Header.module.scss";
import RequestForm from "../RequestForm/RequestForm";
import Modal from "../utils/Modal/Modal";
import { headerHamburgerLinks } from ".././utils/data";
import { Divide as Hamburger } from "hamburger-react";

import { HashLink as Link } from "react-router-hash-link";

const Header = ({
  closeModal,
  onRequestBtnClick,
  modal,
}) => {
  const [isOpen, setOpen] =
    useState(false);

  const handleClickScroll = (elId) => {
    const element =
      document.getElementById(elId);

    if (element) {
      window.dataLayer.push({
        event:
          "onHeader_navigation_Click",
      });
      const scrollTo =
        element.offsetTop - 85;

      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    }

    isOpen && setOpen(!isOpen);
  };

  return (
    <section
      className={s.header__section}
    >
      <div
        className={
          s.bottom__line +
          " " +
          "container"
        }
      >
        <div className={s.wrap}>
          <a
            className={s.logo__wrap}
            href="/"
          >
            <img
              className={s.logo__img}
              src={require("../../images/logo_choizy.png")}
              alt="Choizy-logo"
            />
            <p className={s.logo__desc}>
              for school
            </p>
          </a>
          <nav className={s.menu}>
            <ul
              className={s.menu__list}
            >
              <li
                className={s.menu__item}
              >
                <Link
                  to="#about"
                  className={
                    s.menu__link
                  }
                  scroll={() =>
                    handleClickScroll(
                      "about"
                    )
                  }
                >
                  About
                </Link>
                <span
                  className={
                    s.menu__link_line
                  }
                ></span>
              </li>
              <li
                className={s.menu__item}
              >
                <Link
                  to="#how_it_works"
                  className={
                    s.menu__link
                  }
                  scroll={() =>
                    handleClickScroll(
                      "how_it_works"
                    )
                  }
                >
                  How it works
                </Link>

                <span
                  className={
                    s.menu__link_line
                  }
                ></span>
              </li>
              <li
                className={s.menu__item}
              >
                <Link
                  to="#products"
                  className={
                    s.menu__link
                  }
                  scroll={() =>
                    handleClickScroll(
                      "products"
                    )
                  }
                >
                  Products
                </Link>

                <span
                  className={
                    s.menu__link_line
                  }
                ></span>
              </li>
              <li
                className={s.menu__item}
              >
                <Link
                  to="#FAQ"
                  className={
                    s.menu__link
                  }
                  scroll={() =>
                    handleClickScroll(
                      "FAQ"
                    )
                  }
                >
                  FAQ
                </Link>
                <span
                  className={
                    s.menu__link_line
                  }
                ></span>
              </li>
              <li
                className={s.menu__item}
              >
                <Link
                  to="#contacts"
                  className={
                    s.menu__link
                  }
                  scroll={() =>
                    handleClickScroll(
                      "contacts"
                    )
                  }
                >
                  Contacts
                </Link>
                <span
                  className={
                    s.menu__link_line
                  }
                ></span>
              </li>
            </ul>
          </nav>
          <button
            className={s.request__btn}
            onClick={onRequestBtnClick}
          >
            Try for free
          </button>

          <div
            className={
              s.hamburger__wrap
            }
          >
            <Hamburger
              toggled={isOpen}
              toggle={setOpen}
            />
            <div
              className={
                isOpen
                  ? s.hamburger__list_open
                  : s.hamburger__list_close
              }
            >
              <ul
                className={
                  s.hamburger__list_style
                }
              >
                {headerHamburgerLinks.map(
                  (el) => (
                    <li
                      className={
                        s.hamburger__item
                      }
                      key={el.id}
                    >
                      <Link
                        to={`#${el.link_href}`}
                        className={
                          s.hamburger__link
                        }
                        scroll={() =>
                          handleClickScroll(
                            el.link_href
                          )
                        }
                      >
                        {el.name}
                      </Link>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
        {modal.open && (
          <Modal
            handleClose={closeModal}
          >
            <RequestForm
              closeModal={closeModal}
            />
          </Modal>
        )}
      </div>
      {/* <div className={s.header__promotion}>
        <p className={s.header__promotionText}>
          Request demo now and get FREE access till the end of school year
        </p>
      </div> */}
    </section>
  );
};

export default Header;
