import s from "./Footer.module.scss";
import Facebook from "../../images/facebook.svg";
import LinkedIn from "../../images/linkedIn.svg";
import Instagram from "../../images/instagram.svg";
import decorLine from "../../images/dec_line.png";

import { useState } from "react";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { fetchRequestForm } from "../utils/fetch";
import Loader from "../utils/Loader";

const initialForm = {
  title:
    "New schools contact us request",
  name: "",
  email: "",
  schoolName: "",
  position: "",
  whatsApp: "",
  // track: [],
};

const initialFormErrors = {
  name: false,
  email: false,
  schoolName: false,
  position: false,
  whatsApp: false,
  // track: false,
};

const loaderWrapStyle = {
  position: "absolute",
  bottom: -5,
  left: "50%",
  transform: "translate(-50%)",
};

const Footer = () => {
  const [form, setForm] = useState(
    initialForm
  );
  const [errors, setErrors] = useState(
    initialFormErrors
  );
  // const [radioValue, setRadioValue] = useState("");
  const [loading, setLoading] =
    useState(false);

  const onInputChange = (e) => {
    const { name, value } = e.target;

    name === "track"
      ? setForm({
          ...form,
          track: [
            ...form.track,
            value.trimStart(),
          ],
        })
      : setForm({
          ...form,
          [name]: value.trimStart(),
        });
  };

  const onNavigationClick = () => {
    window.dataLayer.push({
      event:
        "onFooter_navigation_click",
    });
  };

  const onSocialIconClick = () => {
    window.dataLayer.push({
      event:
        "onFooterSocial_Icon_click",
    });
  };

  const onFromSubmit = async (e) => {
    e.preventDefault();
    const isFormValid =
      onRequestFormValidation();
    if (isFormValid) {
      setLoading(true);

      const res =
        await fetchRequestForm(form);
      window.dataLayer.push({
        event:
          "onFreeTrail_form_submit",
      });
      if (res) {
        setLoading(false);
        Notify.success(
          "Request successfully submitted"
        );
        setForm(initialForm);
      } else {
        setLoading(false);
        setForm(initialForm);
        setErrors(initialFormErrors);
        Notify.failure(
          "Something goes wrong.Please try again "
        );
      }
    }
  };

  const regex =
    /^\+(?:[0-9] ?){6,14}[0-9]$/;

  const onRequestFormValidation =
    () => {
      let newErrors = {};
      let isValid = true;
      if (form.name === "") {
        newErrors.name = true;
        isValid = false;
      }
      if (form.email === "") {
        newErrors.email = true;
        isValid = false;
      }
      if (form.schoolName === "") {
        newErrors.schoolName = true;
        isValid = false;
      }
      if (form.position === "") {
        newErrors.position = true;
        isValid = false;
      }
      const inNumberValid = regex.test(
        form.whatsApp.trim()
      );
      if (
        form.whatsApp.trim() !== "" &&
        !inNumberValid
      ) {
        newErrors.whatsApp = true;
        isValid = false;
      }
      // if (form.track.length === 0) {
      //   newErrors.track = true;
      //   isValid = false;
      // }
      setErrors(newErrors);
      return isValid;
    };

  const onInputErrorsSet = (e) => {
    const inputName = e.target.name;

    setErrors({
      ...errors,
      [inputName]: false,
    });
  };
  return (
    <footer
      className={s.footer}
      id="contacts"
    >
      <div className="container">
        <div
          className={s.footer__wrapper}
        >
          <div
            className={
              s.footer__logoWrap
            }
          >
            <a
              className={s.footer__logo}
              href="/"
            >
              <img
                src={require("../../images/logo_choizy.png")}
                alt="Choizy-logo"
              />
              <p
                className={
                  s.footer__logoDesc
                }
              >
                for school
              </p>
            </a>
            <span
              className={
                s.footer__decor_line
              }
              style={{
                backgroundImage: `url(${decorLine})`,
              }}
            ></span>
            <p
              className={
                s.footer__heading
              }
            >
              Ready to get started?
            </p>
          </div>
          <div
            className={
              s.footer__formWrap
            }
          >
            <p
              className={
                s.footer__formHeading
              }
            >
              Contacts:
            </p>
            <div
              className={
                s.footer__formText_wrap
              }
            >
              <p
                className={
                  s.footer__formInstruction
                }
              >
                Fill up the form to get
                1 mouth of fre trial for
                your school
              </p>
              {/* <p className={s.footer__form_checkboxHeading}>
                Choose the track you are interested in:
              </p> */}
            </div>
            <form
              className={s.footer__form}
              onSubmit={onFromSubmit}
            >
              <div
                className={
                  s.footer__formWrap
                }
              >
                <input
                  onChange={
                    onInputChange
                  }
                  onFocus={(e) => {
                    onInputErrorsSet(e);
                  }}
                  placeholder={
                    errors.schoolName
                      ? "Please enter School name"
                      : "School name"
                  }
                  className={[
                    !errors.schoolName
                      ? s.footer__formInput +
                        " " +
                        "inputFooterNormal"
                      : s.footer__formInput +
                        " " +
                        "inputFooterError",
                  ]}
                  value={
                    form.schoolName
                  }
                  type="text"
                  name="schoolName"
                />
                <input
                  onChange={
                    onInputChange
                  }
                  onFocus={(e) => {
                    onInputErrorsSet(e);
                  }}
                  placeholder={
                    errors.name
                      ? "Please enter your name"
                      : "Your name"
                  }
                  className={[
                    !errors.name
                      ? s.footer__formInput +
                        " " +
                        "inputFooterNormal"
                      : s.footer__formInput +
                        " " +
                        "inputFooterError",
                  ]}
                  value={form.name}
                  name="name"
                  type="text"
                />
                <input
                  onChange={
                    onInputChange
                  }
                  onFocus={(e) => {
                    onInputErrorsSet(e);
                  }}
                  placeholder={
                    errors.position
                      ? "Please enter you position"
                      : "Your position"
                  }
                  className={[
                    !errors.position
                      ? s.footer__formInput +
                        " " +
                        "inputFooterNormal"
                      : s.footer__formInput +
                        " " +
                        "inputFooterError",
                  ]}
                  value={form.position}
                  name="position"
                  type="text"
                />
              </div>

              <div
                className={
                  s.footer__form_checkboxWrap
                }
              >
                <input
                  onChange={
                    onInputChange
                  }
                  onFocus={(e) => {
                    onInputErrorsSet(e);
                  }}
                  placeholder={
                    errors.email
                      ? "Please enter you Email"
                      : "Email"
                  }
                  className={[
                    !errors.email
                      ? s.footer__formInput +
                        " " +
                        "inputFooterNormal"
                      : s.footer__formInput +
                        " " +
                        "inputFooterError",
                  ]}
                  name="email"
                  value={form.email}
                  type="email"
                />
                <input
                  onChange={
                    onInputChange
                  }
                  onFocus={(e) => {
                    onInputErrorsSet(e);
                  }}
                  placeholder="Your WhatsApp (optional)"
                  className={[
                    !errors.whatsApp
                      ? s.footer__formInput +
                        " " +
                        "inputFooterNormal"
                      : s.footer__formInput +
                        " " +
                        "inputFooterError",
                  ]}
                  name="whatsApp"
                  value={form.whatsApp}
                  type="text"
                />
                {errors.whatsApp && (
                  <p
                    className={
                      s.footer__formWhatApp_wrap_error
                    }
                  >
                    Should be +(Country
                    code) number
                  </p>
                )}

                {/* <label
                  className={[
                    !errors.track
                      ? s.footer__form_item +
                        " " +
                        "footerCheckboxNorm"
                      : s.footer__form_item +
                        " " +
                        "footerCheckboxErr",
                  ]}
                >
                  Subject track (IB)
                  <input
                    className={
                      s.footer__form_checkboxInput
                    }
                    onClick={(e) => {
                      onInputChange(e);
                      onInputErrorsSet(
                        e
                      );
                    }}
                    // onChange={(event) => setRadioValue(event.target.value)}
                    type="checkbox"
                    name="track"
                    value="Subject track (IB)"
                  />
                  <span
                    // className={s.geekmark}
                    className={[
                      !errors.track
                        ? s.geekmark +
                          " " +
                          "geekmarkNorm"
                        : s.geekmark +
                          " " +
                          "geekmarkErr",
                    ]}
                  ></span>
                </label>
                <label
                  className={[
                    !errors.track
                      ? s.footer__form_item +
                        " " +
                        "footerCheckboxNorm"
                      : s.footer__form_item +
                        " " +
                        "footerCheckboxErr",
                  ]}
                >
                  Career track
                  <input
                    className={
                      s.footer__form_checkboxInput
                    }
                    onClick={(e) => {
                      onInputChange(e);
                      onInputErrorsSet(
                        e
                      );
                    }}
                    // onChange={(event) => setRadioValue(event.target.value)}
                    type="checkbox"
                    name="track"
                    value="Career track"
                  />
                  <span
                    className={[
                      !errors.track
                        ? s.geekmark +
                          " " +
                          "geekmarkNorm"
                        : s.geekmark +
                          " " +
                          "geekmarkErr",
                    ]}
                  ></span>
                </label>
                <label
                  className={[
                    !errors.track
                      ? s.footer__form_item +
                        " " +
                        "footerCheckboxNorm"
                      : s.footer__form_item +
                        " " +
                        "footerCheckboxErr",
                  ]}
                >
                  Choizy track
                  <input
                    className={
                      s.footer__form_checkboxInput
                    }
                    onClick={(e) => {
                      onInputChange(e);
                      onInputErrorsSet(
                        e
                      );
                    }}
                    // onChange={(event) => setRadioValue(event.target.value)}
                    type="checkbox"
                    name="track"
                    value="Choizy track"
                  />
                  <span
                    className={[
                      !errors.track
                        ? s.geekmark +
                          " " +
                          "geekmarkNorm"
                        : s.geekmark +
                          " " +
                          "geekmarkErr",
                    ]}
                  ></span>
                </label>
                {errors.track && (
                  <span
                    className={
                      s.track__error
                    }
                  >
                    Please choose track
                  </span>
                )} */}
                <button
                  className={
                    s.footer__formBtn
                  }
                >
                  Try free trail
                </button>
                {loading && (
                  <Loader
                    loaderWrapStyle={
                      loaderWrapStyle
                    }
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <hr
        className={s.footer__descHr_top}
      />
      <div className="container">
        <ul
          className={s.footer__navList}
        >
          <li
            className={
              s.footer__navItem
            }
            onClick={onNavigationClick}
          >
            <a
              className={
                s.footer__navLink
              }
              href="#about"
            >
              About
            </a>
          </li>
          <li
            className={
              s.footer__navItem
            }
            onClick={onNavigationClick}
          >
            <a
              className={
                s.footer__navLink
              }
              href="#how_it_works"
            >
              How it works
            </a>
          </li>
          <li
            className={
              s.footer__navItem
            }
            onClick={onNavigationClick}
          >
            <a
              className={
                s.footer__navLink
              }
              href="#products"
            >
              Products
            </a>
            <span
              className={
                s.footer__navLink_line
              }
            ></span>
          </li>
          <li
            className={
              s.footer__navItem
            }
            onClick={onNavigationClick}
          >
            <a
              className={
                s.footer__navLink
              }
              href="#FAQ"
            >
              FAQ
            </a>
          </li>
          <li
            className={
              s.footer__navItem
            }
            onClick={onNavigationClick}
          >
            <a
              className={
                s.footer__navLink
              }
              href="#contacts"
            >
              Contacts
            </a>
          </li>
        </ul>
      </div>
      <hr
        className={
          s.footer__descHr_bottom
        }
      />
      <div
        className={
          s.footer__bottom_wrap +
          " " +
          "container"
        }
      >
        <ul
          className={s.socialIcon__list}
        >
          <li
            className={
              s.socialIcon__item
            }
            onClick={onSocialIconClick}
          >
            <a
              href="https://www.instagram.com/choi_zy/"
              target="_blank"
            >
              <img
                src={Instagram}
                alt="Instagram icon"
              />
            </a>
          </li>
          <li
            className={
              s.socialIcon__item
            }
            onClick={onSocialIconClick}
          >
            <a
              href="https://www.facebook.com/choizy.ukr"
              target="_blank"
            >
              <img
                src={Facebook}
                alt="Facebook icon"
              />
            </a>
          </li>
          <li
            className={
              s.socialIcon__item
            }
            onClick={onSocialIconClick}
          >
            <a
              href="https://www.linkedin.com/company/choizy/mycompany/"
              target="_blank"
            >
              <img
                src={LinkedIn}
                alt="LinkedIn icon"
              />
            </a>
          </li>
        </ul>

        <div className={s.basement}>
          <a
            target="_blank"
            className={s.basement__link}
            href="mailto:info@upnup.tech"
          >
            info@choizy.org
          </a>
          <a
            target="_blank"
            className={s.basement__link}
            // href="../utils/public/Privacy policy.pdf"
            href="https://choizy.org/privacy"
          >
            Privacy
          </a>
          <p
            className={
              s.basement__copyright
            }
          >
            ©️ 2023 Choizy
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
