import HideText from "../utils/HideText/HideText";
import s from "./AboutUs.module.scss";

import decSvg_1 from "../../images/aboutUs_1.svg";
import decSvg_2 from "../../images/aboutUs_2.svg";
import decSvg_3 from "../../images/aboutUs_3.svg";

const AboutUs = () => {
  return (
    <section className={s.aboutUs__section} id="about">
      <div className="container">
        <HideText text={"About us"}>
          <div className={s.aboutUs__wrap}>
            <p className={s.aboutUs__text}>
              We are Ukrainian-British educational startup. Launched in 2019, we
              offer career guidance opportunities for middle and high school
              students. Our app is fully video based, which is the most suitable
              and familiar format for modern teenagers.
            </p>
            <div className={s.aboutUs__descWrap_main}>
              <div className={s.aboutUs__descWrap}>
                <p className={s.aboutUs__value}>480</p>
                <span
                  className={s.aboutUs__line}
                  style={{
                    backgroundImage: `url(${decSvg_1})`,
                  }}
                ></span>
                <p className={s.aboutUs__desc}>CAREERS</p>
              </div>
              <div className={s.aboutUs__descWrap}>
                <p className={s.aboutUs__value}>4</p>
                <span
                  className={s.aboutUs__line}
                  style={{
                    backgroundImage: `url(${decSvg_2})`,
                  }}
                ></span>
                <p className={s.aboutUs__desc}>YEARS</p>
              </div>
              <div className={s.aboutUs__descWrap}>
                <p className={s.aboutUs__value}>50K</p>
                <span
                  className={s.aboutUs__line}
                  style={{
                    backgroundImage: `url(${decSvg_3})`,
                  }}
                ></span>
                <p className={s.aboutUs__desc}>USERS</p>
              </div>
            </div>
          </div>
        </HideText>
      </div>
    </section>
  );
};

export default AboutUs;
