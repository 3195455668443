import s from "./RequestForm.module.scss";
import ModalIcon from "../../images/modalIcon.svg";
import {
  useEffect,
  useState,
} from "react";
import RequestSuccessful from "../RequestSuccessful/RequestSuccessful";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { fetchRequestForm } from "../utils/fetch";
import Loader from "../utils/Loader.jsx";

const initialForm = {
  title: "New schools Request a demo",
  name: "",
  email: "",
  whatsApp: "",
  schoolName: "",
  position: "",
  track: "",
};

const initialFormErrors = {
  name: false,
  email: false,
  whatsApp: false,
  schoolName: false,
  position: false,
};

const loaderWrapStyle = {
  position: "absolute",
  bottom: 0,
  left: "50%",
  transform: "translate(-50%)",
};

const RequestForm = ({
  closeModal,
  track,
}) => {
  const [form, setForm] = useState(
    initialForm
  );
  const [errors, setErrors] = useState(
    initialFormErrors
  );
  const [requestForm, setRequestForm] =
    useState(true);
  const [loading, setLoading] =
    useState(false);
  const [btnDisable, setBtnDisable] =
    useState(false);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value.trimStart(),
    });
  };

  const addTrackToForm = () => {
    if (track)
      setForm({
        ...form,
        track: track,
      });
  };

  const onRequestFormSubmit = async (
    e
  ) => {
    e.preventDefault();

    const isFormValid =
      onRequestFormValidation();

    if (isFormValid) {
      setLoading(true);
      setBtnDisable(true);
      const res =
        await fetchRequestForm(form);
      window.dataLayer.push({
        event: "onRequest_form_submit",
      });

      if (res) {
        setLoading(false);
        setRequestForm(false);
        setForm(initialForm);
        setErrors(initialFormErrors);
        setBtnDisable(false);
      } else {
        setLoading(false);
        setBtnDisable(false);
        setForm(initialForm);
        setErrors(initialFormErrors);
        Notify.failure(
          "Something goes wrong.Please try again "
        );
      }
    }
  };

  const regex =
    /^\+(?:[0-9] ?){6,14}[0-9]$/;

  const onRequestFormValidation =
    () => {
      let newErrors = {};
      let isValid = true;
      if (form.name === "") {
        newErrors.name = true;
        isValid = false;
      }
      if (form.email === "") {
        newErrors.email = true;
        isValid = false;
      }
      const inNumberValid = regex.test(
        form.whatsApp.trim()
      );
      if (
        form.whatsApp.trim() !== "" &&
        !inNumberValid
      ) {
        newErrors.whatsApp = true;
        isValid = false;
      }
      if (form.schoolName === "") {
        newErrors.schoolName = true;
        isValid = false;
      }
      if (form.position === "") {
        newErrors.position = true;
        isValid = false;
      }

      setErrors(newErrors);
      return isValid;
    };

  const onInputErrorsSet = (e) => {
    const inputName = e.target.name;
    setErrors({
      ...errors,
      [inputName]: false,
    });
  };

  useEffect(() => {
    addTrackToForm();
  }, [track]);

  return (
    <div>
      {requestForm ? (
        <div className={s.modal__wrap}>
          <div
            className={
              s.modal__headerWrap
            }
          >
            <h2
              className={
                s.modal__header
              }
            >
              Try for free
            </h2>

            <img
              className={
                s.modal__headerClose_icon
              }
              src={ModalIcon}
              alt="remove icon"
              onClick={closeModal}
            />
          </div>
          <p className={s.modal__text}>
            Provide your contact
            information to receive free
            access to the Choizy app.
          </p>
          <form
            className={s.modal__form}
            onSubmit={
              onRequestFormSubmit
            }
          >
            <label
              className={
                s.modal__form_label
              }
            >
              Name
              <input
                onChange={onInputChange}
                className={[
                  !errors.name
                    ? s.modal__form_input +
                      " " +
                      "inputNormal"
                    : s.modal__form_input +
                      " " +
                      "inputError",
                ]}
                onFocus={(e) => {
                  onInputErrorsSet(e);
                }}
                type="text"
                placeholder={
                  errors.name
                    ? "Enter name"
                    : "Your name"
                }
                name="name"
                value={form.name}
              />
            </label>
            <label
              className={
                s.modal__form_label
              }
            >
              Email
              <input
                onChange={onInputChange}
                className={[
                  !errors.email
                    ? s.modal__form_input +
                      " " +
                      "inputNormal"
                    : s.modal__form_input +
                      " " +
                      "inputError",
                ]}
                onFocus={(e) => {
                  onInputErrorsSet(e);
                }}
                type="email"
                placeholder="Enter your email"
                name="email"
                value={form.email}
              />
            </label>
            <label
              className={
                s.modal__form_label
              }
            >
              WhatsApp (optional)
              <input
                onChange={onInputChange}
                className={[
                  !errors.whatsApp
                    ? s.modal__form_input +
                      " " +
                      "inputNormal"
                    : s.modal__form_input +
                      " " +
                      "inputError",
                ]}
                onFocus={(e) => {
                  onInputErrorsSet(e);
                }}
                type="text"
                placeholder="Enter your WhatsApp number"
                name="whatsApp"
                value={form.whatsApp}
              />
              {errors.whatsApp && (
                <p
                  className={
                    s.modal__form_whatsApp_error
                  }
                >
                  Should be +(Country
                  code) number
                </p>
              )}
            </label>
            <label
              className={
                s.modal__form_label
              }
            >
              School name
              <input
                onChange={onInputChange}
                className={[
                  !errors.schoolName
                    ? s.modal__form_input +
                      " " +
                      "inputNormal"
                    : s.modal__form_input +
                      " " +
                      "inputError",
                ]}
                onFocus={(e) => {
                  onInputErrorsSet(e);
                }}
                type="text"
                placeholder="Enter your school name"
                name="schoolName"
                value={form.schoolName}
              />
            </label>
            <label
              className={
                s.modal__form_label
              }
            >
              Position
              <input
                onChange={onInputChange}
                className={[
                  !errors.position
                    ? s.modal__form_input +
                      " " +
                      "inputNormal"
                    : s.modal__form_input +
                      " " +
                      "inputError",
                ]}
                onFocus={(e) => {
                  onInputErrorsSet(e);
                }}
                type="text"
                placeholder={
                  errors.position
                    ? "Enter your role"
                    : "What best describes your role?"
                }
                name="position"
                value={form.position}
              />
            </label>
            <button
              disabled={btnDisable}
              className={
                s.modal__form_btn
              }
            >
              Send
            </button>
            {loading && (
              <Loader
                loaderWrapStyle={
                  loaderWrapStyle
                }
              />
            )}
          </form>
        </div>
      ) : (
        <RequestSuccessful
          closeModal={closeModal}
          // setRequestForm={setRequestForm}
        />
      )}
    </div>
  );
};

export default RequestForm;
