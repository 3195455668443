import s from "./Reviews.module.scss";
import { ReviewsData } from "../utils/data";
import HideText from "../utils/HideText/HideText";

import { useEffect, useRef, useState } from "react";

import "@splidejs/react-splide/css";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";

const Reviews = () => {
  const splideRef = useRef(null);
  const progressBarRef = useRef(null);

  const [showMoreMap, setShowMoreMap] = useState({});
  const [showProgressBar, setShowProgressBar] = useState(true);

  const handleClickMore = (id) => {
    setShowMoreMap((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const handleClickLess = (id) => {
    setShowMoreMap((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  useEffect(() => {
    const splide = splideRef.current.splide;
    const bar = progressBarRef.current;
    // Updates the bar width whenever the carousel moves:
    splide.on("mounted move", () => {
      const end = splide.Components.Controller.getEnd() + 1;
      const rate = Math.min((splide.index + 1) / end, 1);
      bar.style.width = `${100 * rate}%`;
    });

    // cropDescInMemberCards();
    return () => {
      // Unmount the Splide instance when the component unmounts:
      splide.destroy();
    };
  }, [splideRef]);

  const shortedText = (text) => {
    const words = text.split(" ");
    if (words.length > 29) {
      return words.slice(0, 27).join(" ") + "...";
    } else {
      return text;
    }
  };

  return (
    <section id="reviews" className={s.section__reviews}>
      <div className="container">
        <HideText
          text={"Reviews"}
          setShowProgressBar={setShowProgressBar}
          showProgressBar={showProgressBar}
        >
          <div className={s.splide__wrap}>
            <Splide
              ref={splideRef}
              className="splide-slideWrap"
              hasTrack={false}
              aria-label="Reviews"
              options={{
                label: "Reviews",
                type: "slide",
                rewind: true,
                perPage: 2,
                perMove: 1,
                pagination: false,
                breakpoints: {
                  1150: {
                    perPage: 1,
                  },
                },
              }}
            >
              <div className="custom-wrapper">
                <SplideTrack>
                  {ReviewsData.map((el) => (
                    <SplideSlide key={el.id} className={s.splide__item}>
                      <img
                        className={s.splide__img}
                        src={el.img}
                        alt={el.name}
                      />

                      <div className={s.splide__body}>
                        <h2 className={s.splide__name}>{el.name}</h2>

                        <div className={s.splide__placeWrap}>
                          <img
                            className={s.splide__flagImg}
                            src={el.imgFlag}
                            alt="Flag"
                          />

                          <p className={s.splide__placeName}>{el.place}</p>
                        </div>
                        <div>
                          <p className={s.splide__desc} id={el.id}>
                            {!showMoreMap[el.id]
                              ? shortedText(el.desc)
                              : el.desc}

                            {showMoreMap[el.id] ? (
                              <span
                                onClick={() => {
                                  handleClickLess(el.id);
                                }}
                                className={s.showMore__btn}
                              >
                                <br />
                                Show Less
                              </span>
                            ) : (
                              el.desc.split(" ").length > 29 && (
                                <span
                                  onClick={() => {
                                    handleClickMore(el.id);
                                  }}
                                  className={s.showMore__btn}
                                >
                                  Show More
                                </span>
                              )
                            )}
                          </p>
                        </div>
                      </div>
                    </SplideSlide>
                  ))}
                </SplideTrack>
              </div>

              <div className={s.splide__progress}>
                <div
                  style={
                    !showProgressBar
                      ? { display: "none" }
                      : { display: "block" }
                  }
                  className={s.splide__progress__bar}
                  ref={progressBarRef}
                />
              </div>
            </Splide>
          </div>
        </HideText>
      </div>
    </section>
  );
};

export default Reviews;
