import s from "./HowItWorks.module.scss";
import decorLine from "../../images/dec_line.png";
import { howItWorksData } from "../utils/data";

const HowItWorks = ({
  onRequestBtnClick,
}) => {
  return (
    <section
      className={s.section}
      id="how_it_works"
    >
      <div className="container">
        <h2 className={s.heading}>
          How it works
        </h2>
        <ul className={s.desc__list}>
          {howItWorksData.map((el) => (
            <li
              className={s.desc__item}
              key={el.id}
            >
              <img
                src={el.img}
                alt={el.imgAlt}
              />
              <p className={s.header}>
                {el.header}
              </p>
              <span
                className={
                  s.desc__decor_line
                }
                style={{
                  backgroundImage: `url(${decorLine})`,
                }}
              ></span>
              <p className={s.text}>
                {el.text}
              </p>
            </li>
          ))}
        </ul>
        <button
          className={s.request__btn}
          onClick={onRequestBtnClick}
        >
          Try for free
        </button>
      </div>
    </section>
  );
};

export default HowItWorks;
