// const setAuthHeader = (header) =>
//   AsyncStorage.setItem(
//     STORAGE_KEY.AUTH_HEADER_STORAGE,
//     header
//   );
// export const getAuthHeader = () =>
//   AsyncStorage.getItem(STORAGE_KEY.AUTH_HEADER_STORAGE);
// const resetAuthHeader = () =>
//   AsyncStorage.removeItem(STORAGE_KEY.AUTH_HEADER_STORAGE);

// let authInterceptor;

// export const addAuthInterceptor = async (header) => {
//   await setAuthHeader(header);
//   authInterceptor = instance.interceptors.request.use(
//     async (config) => {
//       const token = await getAuthHeader();
//       if (token) {
//         config.headers[
//           STORAGE_KEY.AUTH_HEADER_KEY
//         ] = `Bearer ${token}`;
//       }
//       return config;
//     }
//   );
// };

// export const removeAuthInterceptor = async () => {
//   await resetAuthHeader();
//   instance.interceptors.request.eject(authInterceptor);
// };

// export const login = async (email, password) => {
//   try {
//     const resp = await request("/api/auth/login", POST, {
//       email,
//       password,
//     });

//     if (resp.data?.access_token) {
//       await addAuthInterceptor(resp.data?.access_token);
//     }
//     return resp;
//   } catch (error) {
//     throw error;
//   }
// };

export const fetchRequestForm = async (formData) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(formData),
  };

  try {
    const response = await fetch(
      "https://backend-v2-dot-choizy-prod.nw.r.appspot.com/api/schools/contact-us",
      requestOptions
    );
    const data = await response.json();
    return data.success;
  } catch (error) {
    console.log("error.message :>> ", error.message);
  }
};

export const login = async ({ email, password }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ email, password }),
  };

  try {
    const res = await fetch(
      "https://api-backend.choizy.org/api/auth/login",
      requestOptions
    );

    const data = await res.json();

    return data?.access_token;
  } catch (error) {
    console.log("error.message :>> ", error.message);
  }
};

export const deleteAccount = async (token) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await fetch(
      "https://api-backend.choizy.org/api/users/current/delete",
      requestOptions
    );

    return res.status;
  } catch (error) {
    console.log("error.message :>> ", error.message);
  }
};
