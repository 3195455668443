import s from "./OurProducts.module.scss";
import Camera from "../../images/camera.svg";
import { productsData } from "../utils/data";
import decorLine from "../../images/dec_line.png";
import figures from "../../images/figures.png";
import Modal from "../utils/Modal/Modal";
import RequestForm from "../RequestForm/RequestForm";
import { useState } from "react";

const OurProducts = () => {
  const [track, setTrack] =
    useState("");
  const [modal, setModal] = useState({
    open: false,
    content: null,
  });

  const closeModal = () => {
    document.body.style.overflow =
      "scroll";
    setModal({ open: false });
    setTrack("");
    window.dataLayer.push({
      event:
        "onRequest_productsModal_Close",
    });
  };

  const onRequestBtnClick = (track) => {
    document.body.style.overflow =
      "hidden";
    setModal({ open: true });
    setTrack(track);
    window.dataLayer.push({
      event:
        "onRequest_productsBtn_Click",
    });
  };
  return (
    <section
      className={s.products__section}
      id="products"
    >
      <div className="container">
        <h2
          className={s.products__header}
        >
          Our products
        </h2>
        <div
          className={
            s.products__careerCard_wrap
          }
        >
          <h2
            className={
              s.products__trackCard_header
            }
          >
            Career track
          </h2>
          <div
            className={
              s.products__trackCard_content_wrap
            }
          >
            <div
              className={
                s.products__trackCard_content_secondaryWrap
              }
            >
              <span
                className={
                  s.products__decor_line_left
                }
                style={{
                  backgroundImage: `url(${decorLine})`,
                }}
              ></span>

              <p
                className={
                  s.products__trackCard_content_text
                }
              >
                1 month of free trial
              </p>
              <span
                className={
                  s.products__decor_line_right
                }
                style={{
                  backgroundImage: `url(${decorLine})`,
                }}
              ></span>
            </div>
            <div
              className={
                s.products__trackCard_content_textWrap
              }
            >
              <p
                className={
                  s.products__trackCard_content_desc
                }
              >
                We recommend using this
                track for students who
                are 15 years old and
                above. Students will
                become familiar with 468
                careers from 36 career
                fields and will better
                understand what they
                want to study at
                university.
              </p>
              <p
                className={
                  s.products__trackCard_content_periodDesc
                }
              >
                Duration: 3-4 months
              </p>
              <p
                className={
                  s.products__trackCard_content_infoDesc
                }
              >
                468 careers • more than
                3000 videos • for middle
                and high school students
              </p>
            </div>
            <div
              className={
                s.products__trackCard_button_wrap
              }
            >
              <span
                className={
                  s.products__figure_left
                }
                style={{
                  backgroundImage: `url(${figures})`,
                }}
              ></span>
              <button
                className={
                  s.products__trackCard_btn
                }
                onClick={
                  onRequestBtnClick
                }
              >
                Try for free
              </button>
              <span
                className={
                  s.products__figure_right
                }
                style={{
                  backgroundImage: `url(${figures})`,
                }}
              ></span>
            </div>
          </div>
        </div>
        <ul
          className={s.products__list}
        >
          {productsData.map((el) => (
            <li
              className={
                s.products__item
              }
              key={el.id}
            >
              <p
                className={
                  s.products__heading
                }
              >
                <strong>
                  {
                    el.headingFirstPartBr
                  }
                </strong>
                {el.headingSecondPart}
              </p>

              <p
                className={
                  s.products__desc
                }
              >
                {el.desc}
              </p>
              <p
                className={
                  s.products__purpose
                }
              >
                {el.purpose}
              </p>
              <p
                className={
                  s.products__time
                }
              >
                {el.time}
              </p>
              <span
                className={
                  s.products__line
                }
              ></span>
              <div
                className={
                  s.products__detailedDesc_wrap
                }
              >
                <p
                  className={
                    s.products__detailedDesc
                  }
                >
                  {el.detailedDesc}
                </p>
                {el.detailedDesc_text && (
                  <div
                    className={
                      s.products__detailedDesc_secondaryWrap
                    }
                  >
                    <img
                      className={
                        s.products__detailedDesc_img
                      }
                      src={Camera}
                      alt="Camera"
                    />
                    <p
                      className={
                        s.products__detailedDesc_text
                      }
                    >
                      {
                        el.detailedDesc_text
                      }
                    </p>
                  </div>
                )}
              </div>
              <div
                className={
                  s.products__priseWrap
                }
              >
                {el.price && (
                  <p
                    className={
                      s.products__price
                    }
                  >
                    <b
                      className={
                        s.products__price_num
                      }
                    >
                      FREE
                    </b>
                    <span
                      className={
                        s.products__priceDesc
                      }
                    >
                      till the end of
                      school year
                    </span>
                    {/* <b className={s.products__price_num}> {el.price}</b>
                    <span className={s.products__priceDesc}>/account </span> */}
                  </p>
                )}
                {!el.U_T ? (
                  <button
                    className={
                      s.products__btn
                    }
                    onClick={() =>
                      onRequestBtnClick(
                        el.headingFirstPartBr
                      )
                    }
                  >
                    {el.btnText}
                  </button>
                ) : (
                  <button
                    className={
                      s.products__btn_ut
                    }
                  >
                    {el.btnText}
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
        {modal.open && (
          <Modal
            handleClose={closeModal}
          >
            <RequestForm
              closeModal={closeModal}
              track={track}
              ourProducts
            />
          </Modal>
        )}
      </div>
    </section>
  );
};

export default OurProducts;
